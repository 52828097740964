/* windicss layer utilities */
.dark .dark\:bg-light-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
}
.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.light .light\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-half {
  border-radius: 50%;
}
.rounded-1\/2 {
  border-radius: 50%;
}
.border {
  border-width: 1px;
}
.border-1 {
  border-width: 1px;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.hidden {
  display: none;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.font-700 {
  font-weight: 700;
}
.h-38px {
  height: 38px;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.not-last\:m-2:not(:last-child) {
  margin: 0.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.not-last\:mt-5:not(:last-child) {
  margin-top: 1.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-20 {
  margin-top: 5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-7 {
  padding: 1.75rem;
}
.p-8 {
  padding: 2rem;
}
.p-9 {
  padding: 2.25rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-11 {
  padding: 2.75rem;
}
.p-12 {
  padding: 3rem;
}
.p-13 {
  padding: 3.25rem;
}
.p-14 {
  padding: 3.5rem;
}
.p-15 {
  padding: 3.75rem;
}
.p-16 {
  padding: 4rem;
}
.p-17 {
  padding: 4.25rem;
}
.p-18 {
  padding: 4.5rem;
}
.p-19 {
  padding: 4.75rem;
}
.p-20 {
  padding: 5rem;
}
.p-21 {
  padding: 5.25rem;
}
.p-22 {
  padding: 5.5rem;
}
.p-23 {
  padding: 5.75rem;
}
.p-24 {
  padding: 6rem;
}
.p-25 {
  padding: 6.25rem;
}
.p-26 {
  padding: 6.5rem;
}
.p-27 {
  padding: 6.75rem;
}
.p-28 {
  padding: 7rem;
}
.p-29 {
  padding: 7.25rem;
}
.p-30 {
  padding: 7.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.fixed {
  position: fixed;
}
.left-5 {
  left: 1.25rem;
}
.top-half {
  top: 50%;
}
.left-half {
  left: 50%;
}
.text-center {
  text-align: center;
}
.light .light\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.dark .dark\:text-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(60, 60, 60, var(--tw-text-opacity));
}
.visible {
  visibility: visible;
}
.w-38px {
  width: 38px;
}
.z-60 {
  z-index: 60;
}
.transform {
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  -webkit-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  -ms-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}
.animate {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.blur {
  --tw-blur: blur(8px);
}
.icon-pen {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--tw-icon,1));
  width: 14px;
  height: 4px;
  border-right: 2px solid transparent;
  box-shadow: 0 0 0 2px,inset -2px 0 0;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-right: -2px;
}
.icon-pen::after, .icon-pen::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.icon-pen::before {
  background: currentColor;
  border-left: 0;
  right: -6px;
  width: 3px;
  height: 4px;
  border-radius: 1px;
  top: 0;
}
.icon-pen::after {
  width: 8px;
  height: 7px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 7px solid;
  left: -11px;
  top: -2px;
}
.icon-shape-circle {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--tw-icon,1));
  width: 16px;
  height: 16px;
  border: 3px solid;
  border-radius: 100px;
}
.icon-undo {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--tw-icon,1));
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-left-color: transparent;
  border-radius: 100px;
}
.icon-undo::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  top: -3px;
  left: -1px;
  transform: rotate(-68deg);
}
.icon-redo {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--tw-icon,1));
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-right-color: transparent;
  border-radius: 100px;
}
.icon-redo::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  top: -3px;
  right: -1px;
  transform: rotate(68deg);
}
@media (min-width: 640px) {
  .sm\:w-screen-sm {
    width: 640px;
  }
}
@media (max-width: 639.9px) {
  .\<sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}