.content {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  max-width: 600px;

  .github-log {
    width: 14px;
    vertical-align: bottom;
    margin: 0 5px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: 100%;
  }
}